import { Spinner } from "@material-tailwind/react";
import { FC, PropsWithChildren, memo, useState } from "react";

export enum typeButton {
  button = "button",
  submit = "submit"
}

interface ButtonProps extends PropsWithChildren {
  onClick?: () => void;
  type?: typeButton;
  dataTestId?: string;
  disabled?: boolean;
  className?: string;
  classNameChildren?: string;
  loading?: boolean;
  variant?: "primary" | "link" | "secondary" | "outlined";
}

const mapsColors = {
  ["primary-disabled"]: "bg-slate-400 hover:bg-slate-400 pointer-events-none",
  ["primary"]: "bg-primary-500 hover:bg-primary-500",
  ["secondary"]: "bg-white text-primary-500",
  ["link"]: "bg-transparent text-primary-500 shadow-none",
  ["link-disabled"]:
    "bg-transparent shadow-none text-slate-400 hover:text-slate-400 pointer-events-none",
  ["outlined"]:
    "text-primary-500 border border-primary-500 hover:text-primary-500 shadow-none px-5",
  ["outlined-disabled"]:
    "text-slate-300 border-slate-300 border hover:none shadow-none px-5 pointer-events-none"
};

const loadingClass = "flex items-center justify-center pointer-events-none";

export const NiloButton: FC<ButtonProps> = memo(
  ({
    children,
    className,
    classNameChildren,
    dataTestId,
    disabled,
    loading,
    onClick,
    type,
    variant = "primary"
  }: ButtonProps) => {
    const [isReflecting, setIsReflecting] = useState(false);

    const handleClick = () => {
      setIsReflecting(true);
      setTimeout(() => {
        setIsReflecting(false);
      }, 700);

      if (onClick) {
        onClick();
      }
    };
    const color = mapsColors[disabled ? `${variant}-disabled` : variant];
    return (
      <button
        type={type || typeButton.button}
        disabled={disabled}
        {...(dataTestId ? { "data-test-id": dataTestId } : {})}
        className={`focus:shadow-outline relative overflow-hidden rounded-full p-3 text-xs font-semibold text-gray-100 shadow-lg transition-all duration-700 active:scale-90 ${color} ${className} ${loading && loadingClass}`}
        onClick={handleClick}
      >
        {loading && <Spinner className="me-4 h-4 w-4" />}
        {variant === "primary" && (
          <div
            className={`absolute left-0 top-0 h-[50px] w-full bg-gray-300 transition-all duration-700  ${isReflecting ? "translate-x-full rotate-45" : "-translate-x-full rotate-45"}`}
          ></div>
        )}
        <span
          className={`flex items-center justify-center first-letter:capitalize ${classNameChildren}`}
        >
          {children}
        </span>
      </button>
    );
  }
);
